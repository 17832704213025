import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SocialAuthService } from '@abacritt/angularx-social-login';

import { DeviceDetectorService } from 'ngx-device-detector';

import { ESvgIcons } from 'src/app/core/enums/svg-icons';
import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/core/services/auth.service';
import { TripsService } from 'src/app/core/services/trips.service';
import { UserProfile } from 'src/app/core/interfaces/user-profile';
import { ToastService } from 'src/app/core/services/toast.service';

declare let PDK: any;

enum EShareTab {
  EMAIL,
  EMBED,
}

@Component({
    selector: 'app-shared-dialog',
    templateUrl: './shared-dialog.component.html',
    standalone: false
})
export class SharedDialogComponent implements OnInit {
  readonly reCaptchaKey = environment.recaptchaKey;
  readonly userProfile: UserProfile = this.authService.userProfile;
  readonly eSharedTad = EShareTab;

  shareForm: UntypedFormGroup;
  tripPreview: any;
  shareLink: any;
  selectedTab: EShareTab;
  public readonly breakpointConfig = this.device;
  private embedIframeUrl: any;
  private tripId: string;

  constructor(
    public dialogRef: MatDialogRef<SharedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private toastService: ToastService,
    private tripsService: TripsService,
    private device: DeviceDetectorService,
    private socialAuthService: SocialAuthService,
  ) {
    this.socialAuthService.initState;
  }

  get embedContent(): string {
    return `
        <iframe style="border:none; width:100%; height: 320px;" src="${this.embedIframeUrl}"></iframe>
        <div style="float:right;">
            <a href="${this.shareLink}" target="_blank" rel="noopener noreferrer">Click to visit your trip</a>
        </div>
    `;
  }

  get whatsAppLink(): string {
    const text = encodeURI(
      `Hello, ${this.userProfile?.name} wants to share this trip itinerary with you: ${this.shareLink}`,
    );
    return `https://wa.me/?text=${text}`;
  }

  get snapchatLink(): string {
    return `whatsapp://send?text=Hello, ${this.userProfile?.name} wants to share this trip itinerary with you: ${this.shareLink}`;
  }

  get shareTripName(): string {
    let text = '';
    const getFirstWord = (val): string => {
      return val.slice(0, val.indexOf(','));
    };

    if (this.tripPreview && this.tripPreview.length) {
      const previews = this.tripPreview;
      if (previews[0].name && previews[previews.length - 1] !== undefined) {
        const startingLabel = getFirstWord(previews[0].name);
        const endingLabel = getFirstWord(previews[previews.length - 1].name);
        text +=
          startingLabel +
          ' - ' +
          endingLabel +
          ' | ' +
          this.totalNights() +
          ' day trip';
      } else {
        text += this.totalNights() + ' day trip';
      }
    }

    return text;
  }

  ngOnInit(): void {
    this.initEmailGroup();

    this.tripsService.getTripDone.subscribe((trip) => {
      this.tripId = trip.visible_id;
      this.tripPreview = trip.preview;
      this.shareLink = trip.shareLink;
      this.embedIframeUrl = `https://www.routeperfect.com/embedded.php?id=${this.tripId}`;

      this.shareForm.get('key').patchValue(this.tripId);
      this.shareForm.get('tripName').patchValue(this.shareTripName);
      this.shareForm.get('shareUrl').patchValue(this.shareLink);
    });
  }

  chooseTab(tab: EShareTab): void {
    this.selectedTab = tab;
  }

  shareOnFacebook(): void {
    // const params: UIParams = { method: 'share', href: this.shareLink };
    //
    // this.facebookService
    //   .ui(params)
    //   .then((res: UIResponse) => console.log(res))
    //   .catch((e: any) => console.error(e));
  }

  shareOnPinterest(): void {
    PDK.pin(
      'https://blog-cdn.routeperfect.com/wp-content/uploads/2018/12/05152420/Copy-of-RP-Logo-icon-1.png',
      'RoutePerfect!',
      this.shareLink,
    );
  }

  copiedToClipboardHandler(): void {
    this.toastService.showSuccessSnack('Copied to clipboard');
  }

  recaptchaTokenHandler(token: string): void {
    this.shareForm.get('recaptcha').patchValue(token);
  }

  shareTripByEmail(): void {
    this.tripsService
      .shareTripByEmail(this.shareForm.getRawValue())
      .subscribe((res) => {
        if (res.status === 'OK') {
          this.toastService.showSuccessSnack(res.text.message);
          this.dialogRef.close();
        } else if (res.status === 'error') {
          this.toastService.showErrorSnack(res.message);
        }
      });
  }

  private totalNights(): number {
    return this.tripPreview.reduce((total, itinerary) => {
      const nights = +itinerary.days.slice(0, itinerary.days.indexOf(' '));
      return total + nights;
    }, 1);
  }

  private initEmailGroup(): void {
    this.shareForm = new UntypedFormGroup({
      key: new UntypedFormControl(''),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      tripName: new UntypedFormControl(''),
      shareUrl: new UntypedFormControl(''),
      tempStartDate: new UntypedFormControl(false),
      copyme: new UntypedFormControl(''),
      recaptcha: new UntypedFormControl('', [Validators.required]),
    });
  }

  protected readonly eSvgIcons = ESvgIcons;
}

<div class="rpf-modal__body">
    <div class="rpf-modal__header pb-52">
        <h2 class="rpf-modal__title">Share your trip</h2>

        <button class="rpf-modal__close-btn rpf-modal__close-btn--m-icon" (click)="dialogRef.close()">
            <mat-icon [svgIcon]="eSvgIcons.CLOSE_THIN"></mat-icon>
        </button>
    </div>

    @if (breakpointConfig.isMobile()) {
        <div class="rpf-modal__page-link">
            <p class="title">Page Link</p>
            <div class="input-link">
                <input [placeholder]="shareLink">
                <label (cdkCopyToClipboardCopied)="copiedToClipboardHandler()"
                       [cdkCopyToClipboard]="shareLink">
                    <img src="assets/svg/copy-link-icn.svg" alt=""></label>
            </div>
        </div>
    }


    <div class="rpf-modal__content">
        <ul class="rpf-modal__list more-then-one-line">
            <li class="rpf-modal__list--item" (click)="chooseTab(eSharedTad.EMAIL)">
                <img src="assets/svg/mail-icn-svg.svg" alt="email" class="grey-background">
                <p>Email</p>
            </li>
            <li class="rpf-modal__list--item" (click)="shareOnFacebook()">
                <img src="assets/svg/facebook-icn.svg" alt="facebook" class="background-facebook">
                <p>Facebook</p>
            </li>
            <li class="rpf-modal__list--item" (click)="shareOnPinterest()">
                <img src="assets/svg/pinterest-icn-svg.svg" alt="embed" class="background-pinterest">
                <p>Pinterest</p>
            </li>
            <li class="rpf-modal__list--item">
                <a [attr.href]="whatsAppLink | safeUrl" target="_blank"
                   data-action="share/whatsapp/share">
                    <img src="assets/icon/whatsapp.png" alt="embed" class="grey-background">
                    <p>WhatsApp</p>
                </a>
            </li>
            <li class="rpf-modal__list--item" (click)="chooseTab(eSharedTad.EMBED)">
                <img src="assets/svg/embed-icn-svg.svg" alt="embed" class="grey-background">
                <p>Embed</p>
            </li>
            @if (!breakpointConfig.isMobile()) {
                <li class="rpf-modal__list--item"
                    (cdkCopyToClipboardCopied)="copiedToClipboardHandler()"
                    [cdkCopyToClipboard]="shareLink">
                    <img src="assets/svg/get_link.svg" alt="embed">
                    <p>Get Link</p>
                </li>
            }
        </ul>

        @switch (selectedTab) {
            @case (eSharedTad.EMAIL) {
                <div class="embed-box" [formGroup]="shareForm">
                    <div class="input-box">
                        <input class="input-box__field" formControlName="email" type="text" placeholder="Email address">
                        <span class="input-box__error"></span>
                    </div>
                    <mat-checkbox formControlName="copyme">Send me a copy of the email</mat-checkbox>

                    <div class="embed-box__code">
                        Hello,<br><br>
                        {{ userProfile?.name }} wants to share this trip itinerary with you:<br><br>
                        <ul>
                            @for (item of tripPreview; track idx; let idx = $index) {
                                <li>{{ idx + 1 }}. {{ item.name }} ({{ item.days }})</li>
                            }
                        </ul>
                    </div>

                    <div class="embed-box__re-captcha">
                        <re-captcha (resolved)="recaptchaTokenHandler($event)" [siteKey]="reCaptchaKey"></re-captcha>
                    </div>

                    <div class="rpf-modal__actions rpf-modal__actions--center">
                        <button class="btn-default btn-primary"
                                (click)="shareTripByEmail()"
                                [disabled]="shareForm.invalid">
                            Send
                        </button>
                    </div>
                </div>
            }
            @case (eSharedTad.EMBED) {
                <div class="embed-box">
                    <p>Copy the code below and paste it into your website</p>
                    <div class="embed-box__code">{{ embedContent }}</div>
                </div>
            }
        }
    </div>
</div>
